import React from 'react';
import type { ToastQueue } from './toast-queue';
import { ToastProvider as RadixToastProvider } from '@radix-ui/react-toast';
import { createContext } from '../utils/create-context';

const [Provider, useCtx] = createContext<ToastQueue>('ToastQueue');

export const ToastProvider: React.FC<
  React.PropsWithChildren<{ toastQueue: ToastQueue }>
> = ({ toastQueue, children }) => {
  return (
    <Provider value={toastQueue}>
      <RadixToastProvider>{children}</RadixToastProvider>
    </Provider>
  );
};

// eslint-disable-next-line react-refresh/only-export-components
export const useToast = useCtx;
